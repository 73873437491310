import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import './index.css';
import LoginPortal from './login';
import { RequireToken } from './authStore';
import App from './App';
import AccountSelect from './AccountSelect';

const router = createBrowserRouter([
  {
    path: "/select-account",
    element: <RequireToken>
      <App bannerTitle="Select an Account">
        <AccountSelect />
      </App>
    </RequireToken>
  },
  {path: "login", element: <LoginPortal />},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
