/*******************************************************************************
 * AccountSelect.js
 * 
 * Select a user account for which additional resources may be navigated.
 ******************************************************************************/
import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Box } from "@mui/material";
import AccountCard from './AccountCard';
import { fetchToken } from './authStore';
import { api_client } from './api/auth';
import qs from 'qs';
import "./App.css";

export default function AccountSelect() {
  const [accountsList, setAccountsList] = React.useState([]);

  React.useEffect(()=>{
    getAccountsList();
  },[]);

  const getAccountsList = () => {
    var params = new URLSearchParams();
    params.append("account_types", "ClubMember")
    api_client.get("user/accounts", {
      withCredentials: true,
      params: params,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then(jsonData => {
      // Record the Member Accounts
      setAccountsList(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  return (
    <>
      <CssBaseline />
      <Box m={2} sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2 }}
        >
          {accountsList.map((singleAccount, _) => (
            <Grid item sm={3} xs={12}>
              <AccountCard accountData={singleAccount} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
  