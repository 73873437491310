/*******************************************************************************
 * auth.js
 * 
 * Admin portal authentication provider.
 ******************************************************************************/

import { useLocation, Navigate } from "react-router-dom"
import { refreshTokenCall } from "./api/auth"

export const setToken = (token) => {
   localStorage.setItem('bestbetterapptoken', token);
}
 
export const fetchToken = (token) => {
   return localStorage.getItem('bestbetterapptoken');
}

export const clearToken = () => {
   localStorage.removeItem('bestbetterapptoken');
}
 
export function RequireToken({children}){
 
   refreshTokenCall({redirect: true});
   let auth = fetchToken()
   let location = useLocation()

   if (!auth) {
      return <Navigate to='/login' state ={{from : location}}/>;
   }

   return children;
}
