import React, { useState } from 'react';
import {
  Card, CardActionArea, CardContent, CardMedia, Typography
} from '@mui/material';
import "./App.css";

export default function AccountCard({
  accountData, // Data corresponding to the account to display
}) {
  const [hovered, setHovered] = useState(false);

  const navigate = () => {
    // Relative Path
    const host_url = new URL(window.location.href);
    const new_url = new URL("/zsuite-user-photos/" + accountData.id, host_url);
    window.location.href = new_url.href;
  }

  return (
    <Card
      onClick={navigate}
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      style={{
        transform: `${hovered ? 'scale(1.2,1.2)' : 'scale(1,1)'}`,
        zIndex: `${hovered ? '999' : '1'}`
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="240"
          image={"https://4h.zsuite.org" + accountData.photoUrl}
          alt="account-image"
          style={{
            transform: `${hovered ? 'scale(1.2,1.2)' : 'scale(1,1)'}`,
            zIndex: `${hovered ? '999' : '1'}`
          }}
        />
        <CardContent color="primary.main">
          <br/>
          <Typography gutterBottom variant="h5" component="div">
            {accountData.firstName + " " + accountData.lastName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
